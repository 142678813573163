<template>
  <div class="bbr-workout-extra-categories">
    <main-app-bar :showSearchIcon="false">
      <template v-slot:title> Workout Extra Settings </template>
    </main-app-bar>

    <div class="px-12 mb-12">
      <v-row>
        <v-col cols="12" sm="4">
          <div class="mt-10 mb-5">
            <h3>Workout Extra Categories</h3>
          </div>
          <list
            title="Workout Extra Categories"
            :items="workoutExtraCategories_()"
            :loading="isFetchingWorkoutExtraCategories"
            @list-item-click="fetchWorkoutExtraSubCategories"
            alias="Workout Extra Category"
            :menu="workoutExtraCategoryMenu"
            @menu-item-click="menuItemClick"
            @new-list-item="newWorkoutExtraCategory"
            :sorting="isSortingWorkoutExtraCategories"
            @save-sort="saveSortWorkoutExtraCategories"
            @cancel-sort="cancelSortWorkoutExtraCategories"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <div class="mt-10 mb-5">
            <h3>Sub Categories</h3>
          </div>
          <list
            title="Workout Extra Sub Categories"
            :items="workoutExtraSubCategories"
            :loading="isFetchingWorkoutExtraSubCategories"
            :enableCreate="!!selectedWorkoutExtraCategory.id"
            alias="Sub Category"
            :menu="workoutExtraCategoryMenu"
            @menu-item-click="menuItemClick"
            @new-list-item="newWorkoutExtraSubCategory"
            :sorting="isSortingWorkoutExtraSubCategories"
            @save-sort="saveSortWorkoutExtraSubCategories"
            @cancel-sort="cancelSortWorkoutExtraSubCategories"
          />
        </v-col>
      </v-row>
    </div>

    <workout-extra-category-modal
      v-model="targetWorkoutExtraCategory"
      @save="saveWorkoutExtraCategory"
      @close="targetWorkoutExtraCategory = null"
    />
    <workout-extra-sub-category-modal
      v-model="targetWorkoutExtraSubCategory"
      @save="saveWorkoutExtraSubCategory"
      @close="targetWorkoutExtraSubCategory = null"
    />
    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import WorkoutExtraCategoryModal from '@/components/modals/WorkoutExtraCategoryModal'
import WorkoutExtraSubCategoryModal from '@/components/modals/WorkoutExtraSubCategoryModal'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import List from '@/components/elements/workout-extras/List'
import MainAppBar from '@/layouts/shared/MainAppBar'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mdiMagnify, mdiPlus } from '@mdi/js'

const MENU_EDIT = { title: 'Edit' }
const MENU_DELETE = {
  title: 'Delete',
  class: 'red--text px-1',
}

export default {
  name: 'WorkoutExtraCategoriesPage',

  components: {
    WorkoutExtraCategoryModal,
    WorkoutExtraSubCategoryModal,
    ConfirmDialog,
    MainAppBar,
    List,
  },

  data() {
    return {
      isFetchingWorkoutExtraCategories: false,
      isFetchingWorkoutExtraSubCategories: false,
      workoutExtraCategoryMenu: [MENU_EDIT, MENU_DELETE],
      targetWorkoutExtraCategory: null,
      targetWorkoutExtraSubCategory: null,
      isSortingWorkoutExtraCategories: false,
      isSortingWorkoutExtraSubCategories: false,
      icons: {
        add: mdiPlus,
        search: mdiMagnify,
      },
    }
  },

  created() {
    this.fetchWorkoutExtraCategories()
  },

  computed: {
    ...mapState({
      selectedWorkoutExtraCategory: (state) =>
        state.workoutExtraCategories.selectedWorkoutExtraCategory,
      selectedWorkoutExtraSubCategory: (state) =>
        state.workoutExtraCategories.selectedWorkoutExtraSubCategory,
    }),

    ...mapGetters({
      workoutExtraCategories_:
        'workoutExtraCategories/getWorkoutExtraCategories',
      workoutExtraSubCategories_:
        'workoutExtraCategories/getWorkoutExtraSubCategories',
    }),

    workoutExtraCategories() {
      return this.workoutExtraCategories_()
    },

    workoutExtraSubCategories() {
      return this.workoutExtraSubCategories_()
    },
  },

  methods: {
    ...mapActions({
      getWorkoutExtraCategories:
        'workoutExtraCategories/getWorkoutExtraCategories',
      getWorkoutExtraSubCategories:
        'workoutExtraCategories/getWorkoutExtraSubCategories',
      saveWorkoutExtraCategory_:
        'workoutExtraCategories/saveWorkoutExtraCategory',
      enableWorkoutExtraCategory_:
        'workoutExtraCategories/enableWorkoutExtraCategory',
      enableWorkoutExtraSubCategory_:
        'workoutExtraCategories/enableWorkoutExtraSubCategory',
      saveWorkoutExtraSubCategory_:
        'workoutExtraCategories/saveWorkoutExtraSubCategory',
      disableWorkoutExtraCategory:
        'workoutExtraCategories/disableWorkoutExtraCategory',
      disableWorkoutExtraSubCategory:
        'workoutExtraCategories/disableWorkoutExtraSubCategory',
      deleteWorkoutExtraCategory:
        'workoutExtraCategories/deleteWorkoutExtraCategory',
      deleteWorkoutExtraSubCategory:
        'workoutExtraCategories/deleteWorkoutExtraSubCategory',
      reorderWorkoutExtraCategories:
        'workoutExtraCategories/reorderWorkoutExtraCategories',
      reorderWorkoutExtraSubCategories:
        'workoutExtraCategories/reorderWorkoutExtraSubCategories',
    }),

    ...mapMutations({
      setWorkoutExtraCategories:
        'workoutExtraCategories/setWorkoutExtraCategories',
      setSelectedWorkoutExtraCategory:
        'workoutExtraCategories/setSelectedWorkoutExtraCategory',
      setWorkoutExtraSubCategories:
        'workoutExtraCategories/setWorkoutExtraSubCategories',
      updateOrInsertWorkExtraCategoryItem:
        'workoutExtraCategories/updateOrInsertWorkExtraCategoryItem',
      removeWorkoutExtraCategoryItem:
        'workoutExtraCategories/removeWorkExtraCategoryItem',
      updateOrInsertWorkExtraSubCategoryItem:
        'workoutExtraCategories/updateOrInsertWorkExtraSubCategoryItem',
      removeWorkoutExtraSubCategoryItem:
        'workoutExtraCategories/removeWorkExtraSubCategoryItem',
    }),

    async fetchWorkoutExtraCategories(page = 1) {
      if (this.isFetchingWorkoutExtraCategories) return

      this.isFetchingWorkoutExtraCategories = true

      let { data, meta } = await this.getWorkoutExtraCategories({ page })

      this.isFetchingWorkoutExtraCategories = false
      if (data.length) {
        data.forEach((item) => {
          item.selected = false
        })
      }
      this.setWorkoutExtraCategories({ data, meta })
    },

    async fetchWorkoutExtraSubCategories(workoutExtraCategory, page = 1) {
      if (this.isFetchingWorkoutExtraSubCategories || !workoutExtraCategory)
        return

      this.setSelectedWorkoutExtraCategory(workoutExtraCategory)

      this.isFetchingWorkoutExtraSubCategories = true

      let { data, meta } = await this.getWorkoutExtraSubCategories({
        page,
        workoutExtraCategory,
      })

      this.isFetchingWorkoutExtraSubCategories = false

      this.setWorkoutExtraSubCategories({ data, meta })
    },
    async showConfirmationDialog(action, category) {
      return this.$refs.confirmDelete.open(
        `${action} ${category}`,
        `This action can affect the visibility of associated workout extras.\n\nAre you sure you want to ${action.toLowerCase()} this?`
      )
    },
    async menuItemClick({ item, menuItem }) {
      /* eslint-disable */
      let itemCategory = {
        isWorkoutCategory: Object.hasOwn(item, 'icon'),
        title: Object.hasOwn(item, 'icon')
          ? 'Workout Extra Category'
          : 'Workout Extra Sub Category'
      }

      if (itemCategory.isWorkoutCategory)
        this.setSelectedWorkoutExtraCategory(item)

      if (menuItem.title === 'Edit') {
        this[
          itemCategory.isWorkoutCategory
            ? 'targetWorkoutExtraCategory'
            : 'targetWorkoutExtraSubCategory'
        ] = item
      } else if (menuItem.title === 'Delete') {
        const confirm = await this.showConfirmationDialog(
          menuItem.title,
          itemCategory.title
        )
        if (
          confirm &&
          (
            await this[
              itemCategory.isWorkoutCategory
                ? 'deleteWorkoutExtraCategory'
                : 'deleteWorkoutExtraSubCategory'
            ](item)
          ).status === 200
        ) {
          this[
            itemCategory.isWorkoutCategory
              ? 'removeWorkoutExtraCategoryItem'
              : 'removeWorkoutExtraSubCategoryItem'
          ](item)
        }
      } else if (menuItem.title === 'Disable') {
        const confirm = await this.showConfirmationDialog(
          menuItem.title,
          itemCategory.title
        )

        if (!confirm) return

        const {
          data:{data: category},
          status
        } = await this[
          itemCategory.isWorkoutCategory
            ? 'disableWorkoutExtraCategory'
            : 'disableWorkoutExtraSubCategory'
        ](item)

        if (status !== 200) return
        this[
          itemCategory.isWorkoutCategory
            ? 'updateOrInsertWorkExtraCategoryItem'
            : 'updateOrInsertWorkExtraSubCategoryItem'
        ](category)
      } else if (menuItem.title === 'Enable') {
        const confirm = await this.showConfirmationDialog(
          menuItem.title,
          itemCategory.title
        )

        if (!confirm) return

        const {
          data:{data: category},
          status
        } = await this[
          itemCategory.isWorkoutCategory
            ? 'enableWorkoutExtraCategory_'
            : 'enableWorkoutExtraSubCategory_'
        ](item)

        if (status !== 200) return
        this[
          itemCategory.isWorkoutCategory
            ? 'updateOrInsertWorkExtraCategoryItem'
            : 'updateOrInsertWorkExtraSubCategoryItem'
        ](category)
      }
    },
    async workoutExtraCategoryMenuItemClick({ item, menuItem }) {
      this.setSelectedWorkoutExtraCategory(item)
      if (menuItem.title === 'Edit') {
        this.targetWorkoutExtraCategory = item
      } else if (menuItem.title === 'Delete') {
        const confirm = await this.showConfirmationDialog(
          menuItem.title,
          'Workout Extra Category'
        )
        if (
          confirm &&
          (await this.deleteWorkoutExtraCategory(item)).status === 200
        ) {
          this.removeWorkoutExtraCategoryItem(item)
        }
      } else if (menuItem.title === 'Disable') {
        const confirm = await this.showConfirmationDialog(
          menuItem.title,
          'Workout Extra Category'
        )

        if (!confirm) return

        const {
          data: {
            data: { workoutExtraCategory }
          },
          status
        } = await this.disableWorkoutExtraCategory(item)

        if (status !== 200) return
        this.updateOrInsertWorkExtraCategoryItem(workoutExtraCategory)
      } else if (menuItem.title === 'Enable') {
        const confirm = await this.showConfirmationDialog(
          menuItem.title,
          'Workout Extra Category'
        )

        if (!confirm) return

        const {
          data: {
            data: { workoutExtraCategory }
          },
          status
        } = await this.enableWorkoutExtraCategory_(item)

        if (status !== 200) return
        this.updateOrInsertWorkExtraCategoryItem(workoutExtraCategory)
      }
    },
    async workoutExtraSubCategoryMenuItemClick({ item, menuItem }) {
      if (menuItem.title === 'Edit') {
        this.targetWorkoutExtraSubCategory = item
      } else if (menuItem.title === 'Delete') {
        const confirm = await this.$refs.confirmDelete.open(
          'Delete Workout Extra Sub Category',
          'This action can affect the visibility of associated workout extras.\n\nAre you sure you want to delete this?'
        )
        if (
          confirm &&
          (await this.deleteWorkoutExtraSubCategory(item)).status === 200
        ) {
          this.removeWorkoutExtraSubCategoryItem(item)
        }
      } else if (menuItem.title === 'Disable') {
        const confirm = await this.$refs.confirmDelete.open(
          'Disable Workout Extra Sub Category',
          'This action can affect the visibility of associated workout extras.\n\nAre you sure you want to disable this?'
        )

        if (!confirm) return

        const {
          data: {
            data: { workoutExtraSubCategory }
          },
          status
        } = await this.disableWorkoutExtraSubCategory(item)

        if (status !== 200) return
        this.updateOrInsertWorkExtraSubCategoryItem(workoutExtraSubCategory)
      } else if (menuItem.title === 'Enable') {
        const confirm = await this.$refs.confirmDelete.open(
          'Enable Workout Extra Sub Category',
          'This action can affect the visibility of associated workout extras.\n\nAre you sure you want to enable this?'
        )
        if (!confirm) return

        const {
          data: {
            data: { workoutExtraSubCategory }
          },
          status
        } = await this.enableWorkoutExtraSubCategory_(item)

        if (status !== 200) return
        this.updateOrInsertWorkExtraSubCategoryItem(workoutExtraSubCategory)
      }
    },

    newWorkoutExtraCategory() {
      this.targetWorkoutExtraCategory = {}
    },

    newWorkoutExtraSubCategory() {
      this.targetWorkoutExtraSubCategory = {}
    },

    saveWorkoutExtraCategory(form) {
      form.$busy = true
      form.$clearErrors()

      this.saveWorkoutExtraCategory_(form)
        .then(data => {
          this.updateOrInsertWorkExtraCategoryItem(data)
          this.targetWorkoutExtraCategory = null
          form.$busy = false
          form.$reset()
        })
        .catch(({ response }) => {
          form.$timeout(() => {
            form.$busy = false

            if (response && response.status === 422) {
              form.$setErrors(response.data.errors)
            }
          })
        })
    },

    saveWorkoutExtraSubCategory(form) {
      form.$busy = true
      form.$clearErrors()

      this.saveWorkoutExtraSubCategory_({
        workoutExtraCategory: this.selectedWorkoutExtraCategory,
        params: form
      })
        .then(data => {
          this.updateOrInsertWorkExtraSubCategoryItem(data)
          this.targetWorkoutExtraSubCategory = null
          form.$busy = false
          form.$reset()
        })
        .catch(({ response }) => {
          form.$timeout(() => {
            form.$busy = false

            if (response && response.status === 422) {
              form.$setErrors(response.data.errors)
            }
          })
        })
    },

    saveSortWorkoutExtraCategories(categories) {
      this.reorderWorkoutExtraCategories(categories)
      this.isSortingWorkoutExtraCategories = false
    },

    saveSortWorkoutExtraSubCategories(categories) {
      this.reorderWorkoutExtraSubCategories({
        workoutExtraCategory: this.selectedWorkoutExtraCategory,
        categories
      })
      this.isSortingWorkoutExtraSubCategories = false
    },

    cancelSortWorkoutExtraCategories() {
      this.isSortingWorkoutExtraCategories = false
    },

    cancelSortWorkoutExtraSubCategories() {
      this.isSortingWorkoutExtraSubCategories = false
    }
  }
}
</script>

<style lang="scss">
.v-card {
  display: flex !important;
  flex-direction: column;

  .category-list {
    &--container {
      flex-grow: 1;
      overflow: auto;
    }

    &--item {
      &:hover {
        cursor: pointer;
        // background-color: var(--v-primary-lighten4) !important;
      }
    }
  }
}
</style>
