<template>
  <v-card height="600px" outlined>
    <v-overlay
      class="primary--text text-center"
      color="grey lighten-4"
      :value="loading"
      :opacity="1"
      absolute
    >
      <v-progress-circular :size="50" color="primary" indeterminate />
      <div class="pa-10">Loading {{ alias }}...</div>
    </v-overlay>

    <v-overlay
      class="primary--text text-center"
      color="white"
      :value="!hasItems && !loading"
      :opacity="1"
      absolute
    >
      <no-list :details="'No ' + alias" width="200" />
      <v-btn
        v-if="enableCreate"
        @click="addNewItem"
        class="px-12"
        color="primary"
      >
        <v-icon class="mr-2"> {{ icons.add }} </v-icon>
        <span> Add New {{ alias }}</span>
      </v-btn>
    </v-overlay>

    <v-card-title v-if="!loading && hasItems">
      <template v-if="isSortableList">
        <v-btn
          v-if="!isSorting_"
          color="accent"
          @click="isSorting_ = true"
          text
        >
          Reorder
        </v-btn>

        <v-btn v-if="isSorting_" @click="cancelSorting" color="error" text>
          Cancel
        </v-btn>
      </template>

      <v-spacer></v-spacer>

      <v-btn
        v-if="isSorting_ && hasSortChanges"
        v-ripple="{ center: true }"
        color="success"
        @click="saveSortChanges"
        text
      >
        Save Changes
      </v-btn>
    </v-card-title>

    <v-divider v-if="!loading && hasItems" />

    <div
      class="category-list--container draggable-list-container"
      style="position: relative"
    >
      <draggable
        v-if="!loading"
        tag="div"
        :list="listItems"
        :animation="180"
        :force-fallback="true"
        fallback-class="chosen"
        ghost-class="moving-ghost-list"
        :group="`exercise-${type}-listItems`"
        handle=".grab-category"
      >
        <template v-for="(item, index) in listItems">
          <div :key="index">
            <list-item
              :key="item.id"
              :item="item"
              :is-editable-list="isEditableList"
              :is-sorting="isSorting_"
              :draggable="isSorting_"
              @list-item-click="itemClick"
              @menu-item-click="menuItemClick"
              :menu="menu"
            />

            <v-divider :key="`item-${item.id}-divider`" />
          </div>
        </template>
      </draggable>
    </div>

    <v-card-actions
      v-if="hasItems && !loading && !isSorting_ && enableCreate"
      class="primary lighten-4"
    >
      <v-btn @click="addNewItem" color="primary" block text>
        <v-icon class="mr-2"> {{ icons.add }} </v-icon>
        <span> Add New {{ alias }} </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import NoList from '@/components/elements/NoList'
import ListItem from './ListItem'
import Draggable from 'vuedraggable'

import { clone, sortBy, isEqual, mapValues } from 'lodash'
import { mdiClose, mdiMagnify, mdiPlus } from '@mdi/js'

export default {
  name: 'List',

  components: {
    ListItem,
    Draggable,
    NoList,
  },

  props: {
    items: Object,
    title: String,
    alias: {
      type: String,
      default: 'Item',
    },
    loading: Boolean,
    sorting: Boolean,
    isEditableList: Boolean,
    isSortableList: {
      type: Boolean,
      default(rawProps) {
        return true
      },
    },

    enableCreate: {
      type: Boolean,
      default: true,
    },
    menu: Array,
  },

  data() {
    return {
      isSorting_: false,
      listItems: [],
      preSortListItems: [],
      icons: {
        add: mdiPlus,
        close: mdiClose,
        search: mdiMagnify,
      },
    }
  },

  computed: {
    hasItems() {
      return !!this.items?.list?.length
    },

    type() {
      return this.items.type
    },

    hasSortChanges() {
      return !isEqual(this.listItems, this.preSortListItems)
    },
  },

  methods: {
    itemClick(item) {
      this.$emit('list-item-click', item)
    },

    menuItemClick(menuItem, item) {
      this.$emit('menu-item-click', menuItem, item)
    },

    addNewItem() {
      this.$emit('new-list-item')
    },

    cancelSorting() {
      this.listItems = sortBy(this.listItems, ['order'])
      this.$emit('cancel-sort')
      this.isSorting_ = false
    },

    async saveSortChanges() {
      let _categories = mapValues(this.listItems, 'id')
      this.$emit('save-sort', _categories)
      this.isSorting_ = false
    },
  },

  watch: {
    'items.list'(values) {
      this.listItems = values

      if (!this.isSorting_) {
        this.preSortListItems = clone(values)
      }
    },

    sorting(val) {
      this.isSorting_ = val
    },

    loading(value) {
      if (value) {
        this.cancelSorting()
      }
    },
  },
}
</script>
