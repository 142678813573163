<template>
  <v-dialog v-model="show" width="500" scrollable persistent class="modal">
    <v-card>
      <v-card-title
        >{{ isNew ? 'New' : 'Edit' }} Workout Extra Category
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row class="mt-5">
          <v-col cols="12">
            <v-text-field
              ref="focus"
              v-model="form.name"
              label="Category Name"
              :error-messages="form.$getError('name')"
              :hide-details="!form.$getError('name')"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              label="Description (optional)"
              v-model="form.description"
              auto-grow
              outlined
              flat
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              ref="focus"
              v-model="form.icon"
              label="Icon (optional)"
              :error-messages="form.$getError('icon')"
              :hide-details="!form.$getError('icon')"
              outlined
              flat
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-4">
        <v-spacer />

        <v-btn
          class="mr-4"
          color="error"
          @click="cancel"
          :loading="form.$busy"
          text
        >
          Cancel
        </v-btn>

        <v-btn @click="save(form)" color="primary" :loading="form.$busy">
          Save Category
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Form from '@/utils/form'

export default {
  name: 'WorkoutExtraCategoryModal',

  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      show: false,
      form: new Form({
        id: null,
        name: null,
        description: null,
        order: null,
        icon: null,
      }),
    }
  },

  computed: {
    isNew() {
      return this.value && !this.value.id
    },
  },

  methods: {
    save(form) {
      this.$emit('save', form)
    },

    cancel() {
      this.show = false
    },
  },

  watch: {
    value(val) {
      this.show = !!val
      if (val) {
        this.form = new Form(val)
      }
    },

    show(val) {
      if (!val) this.$emit('close', val)
    },
  },
}
</script>
