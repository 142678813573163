<template>
  <div
    class="category-list--item category-list--active pa-4 align-center d-flex justify-space-between"
    :ripple="true"
    @click="itemClick"
    two-line
    :class="{ 'selected-list-item': selected }"
  >
    <div v-if="draggable" class="mr-2">
      <v-btn @click.stop class="grab-category" icon>
        <v-icon> {{ icons.drag }} </v-icon>
      </v-btn>
    </div>

    <div class="pl-2" :style="{ width: draggable ? '80%' : '90%' }">
      <div class="d-flex justify-space-between">
        <h1 class="subtitle-1 mb-n1 primary--text">
          {{ item.name }}
        </h1>
        <v-chip class="ma-2" x-small v-if="item.deleted_at">Disabled</v-chip>
      </div>
      <div v-if="item.description" class="caption grey--text">
        {{ item.description }}
      </div>
    </div>

    <div class="ml-auto">
      <v-progress-circular
        v-if="loading"
        class="mr-2"
        color="primary"
        style="margin: 5px;"
        :size="25"
        :width="2"
        indeterminate
      />

      <v-menu v-if="!loading && !isSorting && menu && menu.length" left>
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon> {{ icons.actions }} </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(menuItem, index) in generatedMenu"
            :key="index"
            link
            @click.stop="menuItemClick(menuItem, item)"
          >
            <v-list-item-title class="px-1" :class="menuItem.class">
              <span class="mr-3"> {{ menuItem.title }} </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mdiDotsHorizontal, mdiTrashCanOutline, mdiDrag } from '@mdi/js'

export default {
  name: 'ListItem',

  props: {
    type: String,
    item: Object,
    disabled: Boolean,
    draggable: Boolean,
    isSorting: Boolean,
    isEditableList: Boolean,
    enableCreate: Boolean,
    menu: Array,
  },

  data() {
    return {
      loading: false,

      icons: {
        drag: mdiDrag,
        actions: mdiDotsHorizontal,
        remove: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    selected() {
      return this.item.selected
    },
    generatedMenu() {
      if (!this.item.hasOwnProperty('deleted_at')) return this.menu

      return [
        this.item.deleted_at
          ? { title: 'Enable', class: 'green--text px-1' }
          : { title: 'Disable' },
        ...this.menu,
      ]
    },
  },

  methods: {
    itemClick() {
      this.$emit('list-item-click', this.item)
    },

    menuItemClick(menuItem, item) {
      this.$emit('menu-item-click', { menuItem, item })
    },
  },
}
</script>

<style scoped>
.long-and-truncated {
  width: 100%;
  line-clamp: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-list-item {
  background: var(--v-primary-lighten4);
}
</style>
